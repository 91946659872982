---
title: "Business Information"
description: "Business Information"
created: 2025-01-01
updated: 2025-01-01
footer_region: Compliance
rank: 2
show_toc: false
---

# CentralCI Inc.
Private Delaware Corporation

#### Incorporation Date
13 December 2024

#### Registered Address
209 Orange Street, City of Wilmington, County of New Castle, Delaware 19801

#### Address for correspondence
2261 Market Street STE 22601 San Francisco, CA 94114

#### EIN (tax ID)
TBD