---
title: "Master Services Agreement"
description: "Master Services Agreement"
created: 2025-01-01
updated: 2025-01-01
footer_region: Compliance
rank: 5
show_toc: true
---

# Master Services Agreement

**Last updated**: 10 December 2024

Notice: This is the standard Master Services Agreement applicable to CentralCI’s **Enterprise Plan customers**. CentralCI
requires a signed Order Form for Enterprise plan purchases. Please contact our sales team by filling out the form at the
[Contact Sales page](https://centralci.com/contact-sales).

**CUSTOMER ACKNOWLEDGES THAT IT HAS READ THIS AGREEMENT, UNDERSTANDS IT, AND AGREES TO BE BOUND BY ITS TERMS, AND THAT THE
PERSON SIGNING ON ITS BEHALF HAS BEEN AUTHORIZED TO DO SO. THE PERSON EXECUTING THIS AGREEMENT ON CUSTOMER’S BEHALF
REPRESENTS THAT THEY HAVE THE AUTHORITY TO BIND THE CUSTOMER TO THIS AGREEMENT.**

This Master Service Agreement (“**Agreement**”) is entered between **CentralCI Inc.**, a Delaware company with its registered
address at 209 Orange Street, City of Wilmington, County of New Castle, Delaware 19801 (“**CentralCI**”, “**we**”, “**us**”) and the
customer identified in the applicable Order Form (“**Customer**”) and is effective as of the date the Order Form is signed (
“**Effective Date**”). By accepting the Order Form in any manner, the Customer accepts this Agreement and other related
Contracts. This Agreement governs access to, and use of, the CentralCI Platform and/or the Services by the Customer and
its Authorized Users. Certain capitalized terms are defined in Annex A “Definitions” and others are defined contextually
in this Agreement.

## 1. CENTRALCI’S PLATFORM AND SCOPE OF SERVICES

1.1 **Subscription**. During the Term, and provided that this Agreement has not been terminated by either Party, and in
consideration of the payment of the Fees, the Customer and its Authorised Users may access the Platform and use the
Services pursuant and subject to the terms of the applicable Order Form. The Subscription will be purchased based on the
terms and conditions as set forth in the Order Form.

1.2 **Access to the Platform**. The Customer will get access to the Platform and the Services via the Console, CentralCI APIs,
and third-party drivers, as defined in the Documentation. The Customer shall procure that each Authorized User will get
access to the Platform via the Console, the CentralCI API with an API key, or connection strings provided by the Console
or CentralCI API. The Customer may provide access in any format the Customer deems appropriate as long as it is
compatible with CentralCI’s technical configurations (the “Company User Access“). The Customer shall be responsible for
maintaining the records and associations of the API keys and connection strings in the Customer’s environment and
systems.

1.3 **Platform Revisions**. CentralCI may from time to time, in its sole discretion, develop and release certain revisions,
upgrades and updates for new features, bug fixes and enhancements on the Platform (the “Updates”) available to the
Customer for free or at an extra cost. All free Updates will be uploaded/installed automatically without a specific
notice sent to the Customer. When CentralCI introduces paid Updates with greater functionality and/or performance and a
higher price than agreed in the Order Form, the Customer will be able to access the paid Updates by paying extra Fees.

1.4 **Preview Releases**. CentralCI from time to time may make available to the Customer and its Authorized Users use of certain
features, technologies, and services that are not yet generally available, including, but not limited to, any products,
services, or features labeled “alpha”, “beta”, “preview”, “pre-release”, or “experimental”, and any related Content (
each, a “Beta Service”) or access and use of Services available in regions that are not generally available, including,
but not limited to, any regions identified by CentralCI as “alpha”, “beta”, “preview”, “pre-release”, or
“experimental” (each, a “Beta Region”). The Customer and its Authorized Users must comply with all terms related to any
Beta Service or Beta Region as posted on the CentralCI Website or otherwise made available to the Customer and the
Authorized Users. CentralCI may add or modify terms, including lowering or raising any usage limits, related to access
to or use of any Beta Services or Beta Regions at any time. Beta Services or Beta Regions may not be covered by customer
support and/or service level agreements. CentralCI may change or discontinue Beta Services and Beta Regions at any time
without notice. CentralCI also may choose not to release Beta Services and Beta Regions into general availability. Beta
Services and Beta Regions may be inoperable, incomplete or include features that CentralCI may never release, and their
features and performance information are CentralCI’s IP.

1.5 **Integrated Products**. The Platform may include links to other third-party applications, websites, products and services
that could be integrated with CentralCI’s Services (the “Integrated Products”). CentralCI does not endorse any
Integrated Products and is not responsible or liable for the behavior, features, or content of any Integrated Products.
CentralCI does not provide Customer with any rights or remedies regarding such Integrated Products. CentralCI further
makes no express or implied warranties regarding the information, material, products, or services that are contained on
or accessible through any Integrated Products. Any access or use of an Integrated Product is solely at the Customer’s
own risk, directly governed between the Customer and the applicable third-party provider.

1.6 CentralCI may utilize artificial intelligence (“AI”) technologies to enhance user experience and improve CentralCI
Product functionality. Additionally, you acknowledge that the performance of AI functionalities may evolve over time as
the technology advances, and CentralCI reserves the right to update or modify these features accordingly. Your continued
use of CentralCI Products constitutes acceptance of any such updates or modifications.

## 2. PAYMENT TERMS

2.1 **Fees’ Basis**. The Customer shall pay CentralCI the Fees for the Subscription as specified in the applicable Order Form.
The Fees will be based on the functionality, any limitations on the usage capacity, number of Authorised Users, duration
of the Subscription service capacity, and features of the Platform available for the Customer, and any other capacity
limitations, as may be stated in the specific Order Form.
2.2 **Types of Fees**. Each Order Form shall set out the types of Fees relating to the Subscription, which generally can be
categorized as one or a combination of the following:

- 2.2.1 **Commitment Offering**. The Customer shall commit in advance to purchase a specific quantity of the Services as per agreed
rates for use during a Term and to pay upfront or on a periodic basis in advance of use. Additional or other usage (for
example, usage beyond the commitment quantity) may be treated as a Consumption Offering as set out below. Committed
quantities not used during the Term may not roll over to the next Term unless otherwise agreed in the Order Form.  
- 2.2.2 **Consumption Offering** (also called Pay-As-You-Go). The Customer shall pay based on actual usage of the Services in the
preceding period under the rates agreed in the respective Order Form with no upfront commitment. Payments will be made
on a periodic basis in arrears.

2.3 **Payment of Fees**. The Customer will be invoiced the Fees on an annual basis before the commencement of each billing cycle
unless otherwise specified in the Order Form. The Customer will pay the Fees in U.S. dollars within thirty (30) calendar
days from the receipt of a CentralCI’s invoice unless otherwise set forth in the Order Form.

2.4 **Disputable Amounts**. If the Customer reasonably disputes any amount invoiced by CentralCI, the Customer shall pay the
amount of the invoice when due and provide CentralCI with written notice stating the nature of the dispute prior to or
on invoice’s due date. The Customer and CentralCI shall use reasonable commercial diligence to resolve disputes in a
timely manner. CentralCI shall continue performing its obligations in accordance with this Agreement during resolution
of the dispute. All portions of the disputed amount determined to be owed to the Customer shall be refunded within ten (
10) calendar days of the dispute resolution.

2.5 **Downgrades**. Unless otherwise set forth in the Order Form, the Customer shall not be eligible for any refunds or credits
during the existing or any future billing cycle if it decides to decrease or not use the full Platform’s functionality
and service capacity available per the effective then Subscription.

2.6 **Taxes**. All the Fees are exclusive of, and the Customer is responsible for, applicable federal or national, state or
provincial, or local sales, use, excise, export or other applicable taxes, except for taxes on the net income of
CentralCI. Should any payment for the Services be subject to withholding tax by any government, the Customer will
reimburse CentralCI for said withholding tax in full. CentralCI may add any such taxes to the applicable Fees.

## 3. CUSTOMER DATA PROTECTION

3.1 **Privacy Policy and Data Processing Agreement**. The Privacy Policy (available at www.central.com/privacy-policy) and
terms of the Data Processing Agreement (available at 
[centralci.com/data-processing-agreement](https://centralci.com/data-processing-agreement))  (the “DPA”) are hereby incorporated by
reference and will apply to Customer Personal Data. The DPA sets out how we will process Customer Personal Data on your
behalf in connection with the Services provided to you under this Agreement. We will maintain commercially appropriate
administrative, physical, and technical safeguards to protect Customer Personal Data as described in the DPA, including
our Security Measures in Annex 2 of our DPA.

3.2 **Customer’s Authorisation**. The Customer (for itself and all of its Authorized Users) grants CentralCI the right to use
Customer Data solely as necessary (a) to provide, maintain, improve, promote, protect, and update the Services and the
Platform; (b) to prevent or address service, security, support, or technical issues related to the Services and the
Platform; (c) as required by law or as permitted by the Privacy Policy or Data Processing Agreement; or (d) as expressly
permitted in writing by the Customer.  

3.3 **Sub-processors**. The Customer hereby authorizes CentralCI to engage sub-processors for the purposes of providing the
Platform and Services. CentralCI will use all commercially reasonable efforts to ensure that all persons authorized to
process Customer Personal Data on behalf of CentralCI have committed themselves to confidentiality or are under an
appropriate statutory obligation of confidentiality.

3.4 **Required Disclosure**. Notwithstanding the foregoing, CentralCI may disclose the Customer Data, including any Confidential
Information, as required by applicable laws and requirements or regulatory body or governmental authority. CentralCI
shall give the Customer a prompt notice, if permitted by applicable law, of any such legal or governmental request.
CentralCI will use all commercially reasonable efforts to cooperate with the Customer in any effort to seek a protective
order or otherwise to contest such required disclosure, at the Customer’s expense.

3.5 **EU Data Transfers**. We may transfer Customer Data (including Customer Personal Data) to the United States in connection
with the Services. To the extent we process Personal Data that is subject to the protection of European Data Protection
Laws (as defined in the DPA) in the United States, we will process such Personal Data in accordance with the EU-U.S.
Data Privacy Framework, the UK Extension to the EU-U.S. Data Privacy Framework, and the Swiss-U.S. Data Privacy
Framework (collectively, the “Data Privacy Framework”) as set out in our DPA. For more information about the Data
Privacy Framework, please see our [Privacy Policy](privacy-policy).
3.6 **End-User Personal Data and Content**. CentralCI does not knowingly process or collect any Sensitive Information, end-user
Personal Data and end-user-generated Content Transferred through the Platform. The Customer shall be responsible for
ensuring that any Personal Data relating to its Authorized Users or their end-users is Transferred through the Platform
in compliance with any applicable Data Protection Laws. The Customer shall be solely liable for any and all liabilities
arising from or relating to the Sensitive Information, Authorized Users or their end-user Personal Data and Authorized
Users or their end-user-generated Content Transferred through the Platform.
3.7 **Data Accuracy**. Unless expressly stated herein or in the applicable Order Form, CentralCI will have no responsibility or
liability for any Customer Data Transferred through the Platform, nor for any actions taken by the Customer or the
Authorized Users as a result of Customer Data Transfers. CentralCI shall not bear any liability or responsibility in
relation to the accuracy of the Customer Data, nor does CentralCI endorse any opinion contained in any of the Content
Transferred through the Platform.

## 4. IP, FEEDBACK, EXCLUSIVITY

4.1 **Customer’s IP**. CentralCI understands and agrees that the Content and all parts thereof might be protected by copyright
and other applicable laws. The Customer is and will remain the sole and exclusive owner of all the IP rights in and to
its Content Transferred through the Platform. This Agreement does not grant CentralCI any IP rights in or to the Content
or any parts thereof, except for a limited non-exclusive non-transferable non-sublicensable revocable worldwide and
conditional upon compliance with this Agreement license to use the Content in the ways necessary to provide and improve
the Services to the Customer and/or its Affiliates subject at all times to the provisions of this Agreement.

4.2 **IP Rights to the Platform**. CentralCI retains all the IP Rights in and to the Platform and the Services, including
without limitation all software, system software, and applications used to provide the Platform, including any source
code, updates, improvements, enhancements, modifications, or derivative works thereof, whether or not patentable, and
all inventions, content, graphics, media, user interfaces, logos, and trademarks contained in, displayed, performed or
reproduced through the Platform. This Agreement is an agreement for services and does not grant Customer any IP rights
in or to the Platform or any of its components, except for the limited license provided in Section 4.3 below. The
Customer understands and agrees that the Platform and its components are protected by copyright and other applicable
laws.

4.3 **License to Use the Platform**. During the Term, CentralCI grants to the Customer a non-exclusive, non-transferable,
sub-licensable, revocable, worldwide, royalty-free, and conditional on the compliance with this Agreement right to: (a)
access the Platform and use the Services in accordance with the terms of this Agreement and the effective Order Form;
and (b) use any Documentation associated with the Services to support the Customer’s use of the Services (the
“Customer’s License”) .

4.4 **Limited Sub-license**. Subject to the terms of this Agreement and the effective Order Form the Customer has the right to
sublicense the Customer’s License to its end-users in exchange for fees paid via the Customer’s on-platform currency and
provided that the Customer’s end-users shall (i) use the Platform via the Company User Accesses as Authorized Users
and (ii) shall accept and comply with the Contracts all the time when accessing the Platform and/or using the Services.

4.5 **Usage Data and Feedback**. CentralCI may generate and use technical logs, data and learnings about Customer’s and its
Authorized Users’ use of the Platform, the Customer Data in aggregate, anonymized form (the “Usage Data”) to operate,
improve, analyze, and support the Platform and/or the Services and for other lawful business purposes. CentralCI might
use the Customer Data without de-identification, aggregation and anonymization only for troubleshooting purposes. If the
Customer and/or an Authorized User provides CentralCI with Feedback regarding the Platform and/or the Services or other
CentralCI’s offerings, CentralCI may use the Feedback without restriction, obligation, or compensation to the Customer
or its Authorized Users.

4.6 **Exclusivity**. The Customer shall use CentralCI on an exclusive basis during the Term of this Agreement. The Customer
shall not offer to its end-users any Concourse management systems or services without prior written consent of
CentralCI within the Terms of this Agreement.

## 5. USER’S RESTRICTIONS

5.1 **Acceptable Use**. The Customer (and all its Authorized Users) shall use CentralCI in compliance with all applicable laws,
this Agreement, Documentation, and the Contracts (as applicable). The Customer’s and/or any Authorized User’s failure to
maintain Concourse instances within the support perimeter (as specified in the Documentation) may result in the Service’s
failure for which CentralCI is not responsible. Any customization, copies, and use of any additional software with the
Platform and/or the Services may result in the instance falling outside the support perimeter and causing a Service
failure for which CentralCI is not responsible. The Customer (and all of its Authorized Users) is prohibited from: (a)
modifying and/or making derivative works of, disassembling, extracting, reverse compiling and/or reverse engineering the
source code or any part of the Platform;  (b) reselling, offering, selling, renting, leasing, distributing, assigning
and/or otherwise commercially exploiting the Services and/or the Platform for any purposes, in whole or in part, except
where specifically permitted by CentralCI; (c) using and/or accessing the Services and/or the Platform in order to build
a similar and/or competitive website, platform, product and/or service or for other benchmarking or competitive
purposes; (d) framing or otherwise incorporating the Platform or the Services, or any part of Platform, as part of
another website or service; (e) removing and/or destroying any copyright notices and/or other proprietary markings
contained in the Platform; (f) taking any action that imposes or may impose (as determined by CentralCI’s sole and
absolute discretion) an unreasonable and/or disproportionately large load on CentralCI’s and/or our third-party
providers’ infrastructure; (g) accessing the Platform by any other means than through the Company User Accesses; (h)
providing Platform passwords or other login information to any third party other than Authorized Users; (j) exceeding
any usage allowances and service capacity agreed in the applicable Order Form (l) promoting and/or providing information
about illegal activities and/or physical harm and/or injury to any group, individual, institution and/or property; and (
m) Transferring through the Platform any Prohibited Content (jointly referred to as the “**Prohibited Activities**”).

5.2 **Suspension**. If CentralCI discovers or suspects that the Customer or any of the Authorized Users violated any user
restrictions contained in this Section of this Agreement, CentralCI may at its sole discretion without advanced notice
suspend providing Services to the Customer and/or block the Authorized User(s) violating terms of this Section from
accessing the Platform, without prejudice to any other remedies CentralCI will be entitled to under any applicable law.
This Agreement does not require that CentralCI take any action against the Customer or any Authorised User or other
third party for violating this Section 5.1 or this Agreement, but CentralCI is free to take any such action it sees fit.
Where practicable, CentralCI will use reasonable efforts to provide the Customer with prior notice of the suspension or
blockage. Once the Customer resolves the issue requiring suspension or blockage, CentralCI will promptly restore
Customer’s access to the Platform in accordance with this Agreement.

5.3 **Users & Platform Access**. The Customer shall control access to the Platform by the Authorized Users, and the Customer
shall be responsible for their use of the Services in accordance with this Agreement and any applicable law while
accessing the Platform and/or using the Services, including without limitation any Authorised User’s conduct that would
violate the provisions of Section 5.1 hereof. Any breach of this Agreement caused by an Authorized User when accessing
the Platform and/or using the Services will be deemed as a breach by the Customer itself.  

5.4 **Unauthorized Access**. The Customer shall take all commercially reasonable efforts to prevent unauthorized access to the
Platform. The Customer shall notify CentralCI immediately of any known or suspected unauthorized use of the Platform or
breach of its security and shall use best efforts to stop such a breach.

5.5 **Sharing of Projects**. The Customer acknowledges that to the extent Authorized Users are invited to access a third-party’s
Projects, any access to those Projects as well as any Content the Authorized Users submit will be under the sole control
of that other Customer.

5.6 **Public Projects**. The Customer acknowledges that it controls who it shares Projects with (including making Projects
public). CentralCI has no liability for how others may access or use the Customer’s Project from a decision to share a
Project.

## 6. CONFIDENTIALITY

6.1 **Confidential Information**. Definition. “Confidential Information” means (a) terms and conditions of this Agreement, the
Order Form and any technical or performance information about the Services that is not publicly available; (b) any
carve-outs from this Agreement agreed between CentralCI and the Customer in writing; (c) any Customer Data that is not
publicly available; (d) any other information relating a party that is disclosed in writing or orally and is designated
as confidential or proprietary at the time of disclosure (and, in the case of oral disclosures, summarized in writing
within five (5) calendar days of the initial disclosure and delivered to the receiving party), or that due to the nature
of the information the receiving party would clearly understand it to be confidential information of the disclosing
party.

6.2 **Exclusions**. Confidential Information shall not include any information that: (a) was or becomes generally known to the
public through no fault or breach of this Agreement by the receiving party; (b) was rightfully in the receiving party’s
possession at the time of disclosure without restriction on use or disclosure; (c) was independently developed by the
receiving party without use of the disclosing party’s Confidential Information; or (d) was rightfully obtained by the
receiving party from a third party not under a duty of confidentiality and without restriction of use or disclosure. For
the avoidance of doubt, the fact the Customer uses CentralCI Services shall not be treated as confidential.

6.3 **Restricted Use and Non-disclosure**. During and after the Term, the party receiving Confidential Information will: (a) use
the Confidential Information of the other party solely for the purpose for which it is provided; (b) not disclose such
Confidential Information to a third party, except on a need-to-know basis to its directors, officers, representatives,
investors, attorneys, consultants, sub-contractors and service providers who are under confidentiality obligations at
least as restrictive as those contained herein; and (c) protect such Confidential Information from unauthorized use and
disclosure to the same extent (but using no less than a reasonable degree of care) that it protects its own Confidential
Information of a similar nature.

6.4 **Injunction**. Each party agrees that breach of this Section 6 would cause a Disclosing Party substantial harm, for which
monetary damages would not provide adequate compensation, and that in addition to any other available remedy, a
disclosing party will be entitled to appropriate equitable relief against such breach or threatened breach, without
proving actual damage or posting a bond or other security.

6.5 **Termination & Return**. Upon termination of this Agreement or at any time upon written request of the disclosing party,
the receiving party shall return all copies of Confidential Information to the disclosing party or certify, in writing,
the destruction thereof.

## 7. REPRESENTATIONS & WARRANTIES

7.1 **From Both Parties**. Each party represents and warrants that: (a) it has the full right and authority to enter into,
execute, and perform its obligations under this Agreement and that no other legally binding contract, document or
instrument, no pending or threatened claim or litigation known to it would have a material adverse impact on its ability
to perform its obligations as required by this Agreement; (b) it is a corporation, or another entity authorized to do
business pursuant to the applicable law; (c) this Agreement is signed by a duly authorized person who has the legal
capacity to execute and deliver this Agreement.

7.2 **Limited Remedies**. In the event of a breach of the warranty in Section 7.2, CentralCI, at its own option and expense,
will promptly take the following actions as might be needed: (a) secure for the Customer the right to continue using the
Platform; (b) replace or modify the Platform to make it non-infringing, terminate the infringing features of the
Platform; (c) replace the Platform’s features in question with software features of substantially similar functionality;
or (d) if such attempts do not succeed, refund to the Customer any prepaid Fees under the Order Form, in proportion to
the balance of the Term left after such termination. In conjunction with the Customer’s rights to terminate for breach,
where applicable, the preceding sentence states CentralCI’s sole obligation and liability, and the Customer’s sole
remedy for breach of any warranty in Section 7.2.

7.3 **From the Customer**. The Customer represents and warrants that (a) it owns, and/or has the necessary permissions to use
and authorize the use of the Customer Data as described in this Agreement; (b) the Customer Data is legally
unobjectionable and is virus-free; (c) the Customer (and all the Authorised Users) will comply with acceptable use
requirements set forth in Section 5.1 and any technical restrictions set forth in the Contracts and the Documentation.

## 8. DISCLAIMERS

8.1 Except as expressly provided for in Section 8.2. herein, the Platform, the Services and all related components and
information are provided on an “as is” and “as available” basis without any warranties of any kind, and CentralCI
expressly disclaims any and all warranties, whether express or implied, including the implied warranties of
merchantability, title, fitness for a particular purpose, course of performance, and non-infringement. Without limiting
the generality of the foregoing, and save as otherwise set out in section 8.2(b), CentralCI does not represent or
warrant that the Platform and/or the Services will be uninterrupted, timely, secure from hacking or other unauthorized
intrusion, or error-free.  

8.2 CentralCI provides no warranty regarding, and will have no responsibility for, any claim arising out of: (a) a
modification of the Platform’s features or functionality made by anyone other than CentralCI unless CentralCI approves
such modification in writing; or (b) use of the Platform in combination with any operating system not authorized in the
Documentation or with hardware or software specifically forbidden by the Documentation.

8.3 Without limiting any disclaimers in the Agreement or the respective Order Form, Beta Services and Beta Regions are not
ready for general commercial release and may contain bugs, errors, defects, or harmful components. Accordingly, and
notwithstanding anything to the contrary in the Agreement or the respective Order Form, CentralCI will provide Beta
Services and Beta Regions to the Customer and the Authorized Users “as is.” CentralCI shall make no representations or
warranties of any kind, whether express, implied, statutory, or otherwise regarding Beta Services and Beta Regions,
including any warranty that the Beta Services and Beta Regions will become generally available, be uninterrupted,
error-free, or free of harmful components, or that any content, including the Customer Content, will be secure or not
otherwise lost or damaged. Except to the extent prohibited by law, CentralCI disclaims all warranties, including any
implied warranties of merchantability, satisfactory quality, fitness for a particular purpose, non-infringement, or
quiet enjoyment, and any warranties arising out of any course of dealing or usage of trade. CentralCI’s aggregate
liability for any Beta Services and Beta Regions will be limited to the amount of ten US dollars (US$10).

## 9. INDEMNIFICATIONS

9.1 **CentralCI’s Indemnity**. CentralCI shall defend and indemnify the Customer and the Customer’s Associates (as defined
below) against any and all third-party claims, suits, or proceedings, arising out of or resulting from (a) any
allegation that access to the Platform and/or use of the Services infringes or misappropriates a third party’s IP
rights, (b) any disclosure or exposure of Personal Data or other Customer’s Confidential Information in violation of any
applicable Data Protection Laws caused by the act or omission of CentralCI or any of its agents, contractors, or
employees; (c) any wilful misconduct of CentralCI in performing the Services that caused losses or damages to the
Customer (the “Indemnified Claim”). The “Customer’s Associates” means the Customer, its Affiliates, and their respective
officers, directors, employees, successors, and assigns.

9.2 CentralCI’s obligations set forth in this Section 9.1 do not apply to the extent that an Indemnified Claim arises out
of: (a) Customer’s breach of this Agreement; (b) revisions to the Platform made without CentralCI’s written consent; (c)
use of the Platform in combination with integrated products, hardware or software not provided by CentralCI; (d) the
allegation that does not state with specificity that the Services are the basis of the Indemnified Claim; (e) Customer’s
failure to incorporate Updates that would have avoided the alleged infringement, provided such Updates are offered
without extra charged in addition to the Fees set forth in the Order Form.

9.3 **Customer’s Indemnity**. The Customer shall defend and indemnify CentralCI and the CentralCI Associates (as defined below)
against any and all third-party claims, suits, or proceedings, arising out of or resulting from (a) Transfer of the
Prohibited Content through the Platform, (b) any disclosure or exposure of Personal Data or other Customer’s
Confidential Information in violation of any applicable Data Protection Laws caused by the act or omission of the
Customer or any of its Authorised Users (c) any wilful act or omission of the Customer or its Authorised Users while
using the Services that caused losses or damages to CentralCI. “CentralCI’s Associates” means CentralCI, its Affiliates,
and their respective officers, directors, employees, successors, and assigns.  

9.4 **Indemnified Claims’ Procedure**. The obligations of each party in the capacity of the indemnifying party within the
meaning of Section 9.1 and 9.2 respectively are subject to the following: (i) the indemnified party shall provide the
indemnifying party with prompt written notice of the third party claim or action; (ii) the indemnifying party shall have
the option of assuming control over the defence and/or settlement of such claim; (iii) the indemnified party shall
cooperate with the indemnifying party as reasonably requested; provided, however, that such cooperation is at the
indemnifying party’s sole cost and expense; and (iv) the indemnifying party shall not settle any claim or action on
behalf of the indemnified party without first notifying the indemnified party of all details related to such settlement,
and receiving the indemnified party’s prior written consent not to be unreasonably withheld, provided that the
indemnified party will have the absolute right to reject any settlement or compromise that requires that it admit
wrongdoing or liability or subjects it to any ongoing affirmative obligations.

## 10. LIMITATIONS OF LIABILITY

10.1 **Exclusion of Damages**. To the extent permitted by law, in no event will a party be liable to the other party for any
consequential, indirect, special, incidental, punitive damages or loss of profits, revenue, data, or business
opportunities arising out of or related to this Agreement, whether action is in contract or tort and even if the party
knew or should have known that such damages were possible and even if direct damages are not a satisfactory remedy.

10.2 **Dollar Cap**. Unless otherwise stated in this Agreement, each party’s maximum aggregate liability to the other party for
damages of any kind that the other party suffers in connection with this Agreement is limited to the amount of the Fees
paid by the Customer to CentralCI in the twelve (12) month period immediately preceding the date of the last event
giving rise to the liability. These limitations are independent from all other provisions of this Agreement and shall
apply whether an action is in contract or tort and regardless of the theory of liability, notwithstanding the failure of
any remedy provided herein.

10.3 **Specific Exemptions**. CentralCI shall have no liability or responsibility for (a) legitimacy and/or accuracy of the
Customer Data, IP rights claims related to, or arising from, the Content; (b) any Transfers of Sensitive Information; (
c) any scheduled or unscheduled downtime or unavailability of the Platform and/or the Services because of Force
Majeure; ((b) for any claims arising from incorrect Company User Accesses, unauthorized access to the Platform due to
the Customer’s or the Authorized Users gross negligence or wilful misconduct or failure of the Customer to provide
Company User Accesses in compliance with CentralCI’s technical requirements; or (c) suspension of the Customer’s and of
its Authorized Users access to the Platform for cause and termination of this Agreement for any of the reasons set forth
in Section 12.2 “Termination for Cause”.

10.4 **Clarifications**. The liabilities limited by this Section 10 apply: (a) to liability for negligence; (b) regardless of the
form of action, whether in contract, tort, strict product liability, or otherwise; (c) even if the party is advised in
advance of the possibility of the damages in question and even if such damages were foreseeable; and (d) even if the
remedies fail of their essential purposes. If applicable law limits the application of the provisions of this Section
10, the indemnifying party’s liability will be limited to the maximum extent permissible. For the avoidance of doubt, a
party’s liability limits and other rights set forth in this Section 10 apply likewise to that party’s associates (either
CentralCI’s Associates or Customer’s Associates).

## 11. DISPLAY OF LOGOS. NO PUBLICITY.

11.1 **Logo Use**. Neither party shall issue or release any announcement, statement, press release or other publicity or
marketing materials relating to this Agreement or otherwise use the other party’s trademarks, service marks, trade
names, logos, domain names, corporate identifiers or other indicia of source, affiliation or sponsorship, in each case,
without the prior written consent of the other party, except for CentralCI’s right to using Customer’s logo and
corporate identifiers solely to identify Customer as a CentralCI’s customer in its Website and marketing materials. The
Customer hereby grants CentralCI a limited non-transferable right (which is freely revocable at any time by giving email
notice to the Customer’s account manager at CentralCI) for the mentioned purposes, provided CentralCI follows the
Customer’s brand-book requirements and includes relevant trademark attribution in any materials.

## 12. TERM AND TERMINATION

12.1 **Term**. The term of this Agreement shall commence on the Effective Date indicated in the applicable Customer Order and
continue for during all the Subscription term indicated in the outstanding Customer Order (the “Term”). Thereafter, this
Agreement and the applicable Customer Order will be automatically renewed for the successive Term of twelve (12) months
unless the Customer provides at least thirty (30) calendar days prior written notice of termination to CentralCI. In
this case, the Agreement and the Customer Order shall terminate on the date indicated in the notice of termination.

12.2 **Termination for Cause**. Either Party may terminate this Agreement or the Order Form if the other Party: (i) fails to cure
any material breach of this Agreement within fourteen (14) calendar days after written notice of such breach; (ii)
ceases operation without a successor; or (iii) seeks protection under any bankruptcy, receivership, trust deed,
creditors arrangement, composition or comparable proceeding.

12.3 **Effects of Termination**. Upon termination of this Agreement, the Customer and its Authorized Users shall have no access
to the Platform and the Services unless the Customer shall continue to provide Company User Accesses to their end users.
In this case the Authorized Users shall have the right to access the Platform and use the Services under any Free Tier
pricing plan available on the Website by then. Upon CentralCI’s written request the Customer shall cease all use of the
Services and delete, destroy, or erase any Confidential Information in its possession or control. Upon the Customer’s
request, CentralCI shall delete, destroy, or erase all the Customer Data, including any Personal Data and Confidential
Information, in its possession or control, unless storage of such information is required under the applicable laws.
CentralCI shall not be liable for any loss of the Content by the Customer and its Authorized Users in case of
termination of this Agreement.

## 13. MISCELLANEOUS

13.1 **Affiliates’ Orders**. An Affiliate of the Customer may enter its own Order(s) as mutually agreed with CentralCI. This
creates a separate agreement between the Affiliate and CentralCI incorporating this Agreement with the Affiliate treated
as “Customer”. Neither Customer nor any Customer’s Affiliate shall have any rights under each other’s agreement and/or
the Order Form with CentralCI unless explicitly set forth herein, and breach or termination of any such agreement and/or
the Order Form is not breach or termination under any other Affiliate’s applicable documents.

13.2 **Amendment**. We reserve the right to revise, add, delete, change, update, or otherwise modify (the “Change”) all or any
part of this Agreement and other Contracts at our sole and absolute discretion. The most up-to-date version will always
be posted on our Website. We will also amend the “Last Updated” date at the beginning of the Terms once such Changes
have been made. If you would like to receive an email notification when we make material Changes to the Agreement,
complete the form found here. The revised version will become effective and binding the next business day after it is
posted. If Customer accesses or uses the Services after the effective date of the revised Agreement, such access and use
will constitute Customer’s acceptance of the revised Agreement.

13.3 **Sub-contractors**. CentralCI may use sub-contractors to provide Services to Customer under this Agreement, so long as
CentralCI (a) requires such sub-contractors to observe any applicable Data Protection Laws, confidentiality obligations,
and other terms of this Agreement as though they were parties hereto, and (b) remains fully responsible for the
performance or non-performance by any sub-contractors to the same extent as if CentralCI itself performed or failed to
perform.  

13.4 **Technology Export**. The Customer shall comply with all relevant U.S. and foreign export and import laws in using the
Services. The Customer (a) represents and warrants that either the Customer or its Authorized Users are not listed on
any U.S. government list of prohibited or restricted parties or located in (or a national of) a country that is subject
to a U.S. government embargo or that has been designated by the U.S. government as a “terrorist supporting” country, (b)
agrees not to access the Platform or use the Services in violation of any U.S. export embargo, prohibition or
restriction, (c) will not Transfer through the Platform any information controlled under the U.S. International Traffic
in Arms Regulations (d) are an authorized recipient of CentralCI Products and Services in an authorized geographic
location.

13.5 **Notices**. Any notice given pursuant to this Agreement shall be in writing and may be effectively given if delivered
personally, sent by a nationally recognized overnight courier (receipt requested); or sent in electronic format to each
party’s address indicated in the applicable Order Form. Any notice received by the recipient by personal delivery or
email transmission before 4:00 p.m. on a business day in the jurisdiction of the recipient shall be deemed to have been
given on that business day. Any notice received by the recipient by personal delivery or email transmission after 4:00
p.m. on a business day, or any other day, shall be deemed to have been given on the next business day. Notices delivered
in electronic format will not be effective unless the sender receives acknowledgment of receipt from the recipient,
which acknowledgment will not include any machine-generated automatic reply. Either party may change its notice address
by delivery of the proper notice to the other Party.

13.6 **Force Majeure**. Except for payment obligations, neither party will be liable by reason of any failure or delay in the
performance of any obligations on account of events beyond the reasonable control of a party, which may include
denial-of-service attacks, cyber-attacks, malware attacks, a failure by a third-party hosting provider or utility
provider, shortages, riots, fires, pandemics, acts of God, war, strikes, terrorism, and governmental action (the “Force
Majeure”). Either party may terminate this Agreement within five (5) calendar days of a Force Majeure event, without any
refunds of the paid Fees.

13.7 **Assignment & Successors**. A party may not assign this Agreement or any of its rights or obligations hereunder without the
other party’s express written consent except to (a) such party’s Affiliate or (b) a party that acquires all or
substantially all of such party’s assets as part of a corporate merger or acquisition or corporate restructuring. Except
to the extent forbidden in this Section 13.7, this Agreement will be binding upon and inure to the benefit of the
parties’ respective successors and assigns. Any purported assignment, delegation or transfer in violation of this
Section 13.7 is void.

13.8 **Severability**. To the extent permitted by applicable law, the parties hereby waive any provision of law that would render
any clause or section of this Agreement invalid or otherwise unenforceable in any respect. In the event that a provision
of this Agreement is held to be invalid or otherwise unenforceable, such provision will be interpreted to fulfill its
intended purpose to the maximum extent permitted by applicable law, and the remaining provisions of this Agreement will
continue in full force and effect.

13.9 **Survival**. The following provisions will survive termination or expiration of this Agreement: (a) any obligation of the
Customer to pay the Fees (unless the termination is by the Customer for cause); (b) Section 4 “IP & Feedback”, Section 6
“Confidential Information”, Section 7 “Representations andWarranties”, Section 8 “Disclaimers”, Section 9
“Indemnification”, Section 10 “Limitation of Liability”, Section 13 “Miscellaneous” and (c) any other provision of this
Agreement that must survive to fulfil its essential purpose.

13.10 **No Waiver**. Neither party will be deemed to have waived any of its rights under this Agreement by any statement or
representation other than by an authorized representative in an explicit written waiver. No waiver of a breach of this
Agreement will constitute a waiver of any other breach of this Agreement.

13.11 **Choice of Law & Jurisdiction**. This Agreement will be governed solely by the internal laws of the State of Delaware,
without reference to: (a) any conflicts of law principles that would apply the substantive laws of another jurisdiction
to the parties’ rights or duties; (b) the 1980 United Nations Convention on Contracts for the International Sale of
Goods; or (c) other international laws. The parties’ consent to the personal and exclusive jurisdiction of the Chancery
Court of the State of Delaware.

13.12 **Conflicts**. This Agreement, together with all the Order Forms, annexes, schedules, and any other documents incorporated
herein by reference, constitutes the sole and entire agreement of the parties with respect to the subject matter
contained herein, and supersedes all prior and contemporaneous understandings and agreements, both written and oral,
with respect to such subject matter. In the event of any conflict between the terms and provisions of this Agreement and
those of any Order Forms or Contracts, the following order of precedence shall govern: (a) first, the effective then
Order Form; (b) second, this Agreement with all the annexes and schedules, and (c) third, the effective then Contracts.
For the avoidance of doubt, any Customer purchase orders or business forms, requests for proposal (quotation),
supplier/vendor forms and questionnaires will not amend or modify this Agreement and are expressly rejected by
CentralCI; any of these Customer’s documents are for administrative purposes only and have no legal effect.

13.13 **Headings**. The section headings in this Agreement are intended solely for convenience of reference and shall be given no
effect in the construction or interpretation of this Agreement.

13.14 **Independent Contractors**. This agreement does not create an agency, partnership, or joint venture. The parties are
independent contractors and will so represent themselves in all regards. Neither party is the agent of the other, and
neither may make commitments on the other’s behalf.

13.15 **Entire Agreement**. This Agreement sets forth the entire agreement of the parties and supersedes all prior or
contemporaneous writings, negotiations, and discussions with respect to its subject matter. Neither party has relied
upon any such prior or contemporaneous communications.

## ANNEX A “Definitions”

**“Affiliate”** means any entity that directly or indirectly controls, is controlled by, or is under common control with the
subject entity. “Control,” for purposes of this definition, means direct or indirect ownership or control of more than
50% of the voting interests of the subject entity.

**“Authorized User”** means an individual that directly or indirectly through another user Account, including Customer’s
end-users, employee, business partners, contractors, affiliates, representatives or whosoever the Customer granted
permission to access the Platform and/or use the Services under the Customer’s account subject to this Agreement and the
Order Form.

For the avoidance of doubt, the term “Authorized User” does not include individuals or entities when they access the
Platform and/or use the Services under their own accounts on the Platform, rather than via Company User Accesses.

**“Confidential Information”** has the meaning ascribed to it in Section 6 hereof.

**“Content”** means information, including but not limited to, measurable data units, source code, files, folders, texts,
design layouts, pictures, video and other images, audio materials, graphics, document or data files, messages and other
communications, personalization settings and other information and/or content, which is or may be Transferred through
the Platform by the Customer or the Authorized Users, including the Content generated by any Authorized User’s
end-users, and any computational results that the Customer or any Authorized User may derive from the foregoing through
their use of the Services.

**“Contracts”** means collectively (a) CentralCI Master Service Agreement(b) CentralCI [Privacy Policy](privacy-policy);
(c) CentralCI [Cookie Policy](cookie-policy); (d) [Data Processing Agreement](data-processing-agreement) 
and (e) any other operating rules, policies, and documents that may be published from time to time by CentralCI on the Website.

**“Console”** a browser-based graphical user interface provided by CentralCI, designed to facilitate the management,
configuration, oversight, and utilization of various CentralCI services, projects, and resources. This encompasses, but
is not limited to, servers, databases, roles, branches, computational resources, account settings, and any other features or
functionalities as may be introduced, updated, or removed by CentralCI from time to time.

**“Customer”** means a legal person who has accepted this Agreement and purchased a subscription for CentralCI’s enterprise
plan.

**“Customer Data”** means jointly the Content, the Customer Personal Data as well as any other information that the Customer
and/or its Authorized Users Transfers to CentralCI in connection with the use of the Services.

**“Order Form”** means an outstanding and effective document specifying details of the Customer’s Subscription. Each Order
Form executed by the Customer and CentralCI will be deemed an integral part of this Agreement.

**“Customer Personal Data”** means Personal Data that CentralCI processes as a data processor for the Customer for the
purpose of providing the Services. The Customer Personal Data includes Personal Data that the Customer and/or any
Authorized User Transfers through the Platform in connection with its use of the Services.

**“Data Protection Laws”** means all laws and regulations, including laws and regulations of the United States of America,
the European Union (the “EU”), the European Economic Area (the “EEA”) and their member states, Switzerland and the
United Kingdom, applicable to the Processing of Customer Personal Data for the purposes of the Services.

**“Documentation”** means CentralCI’s documentation related to use of the Platform and the Services, posted on the Website
otherwise provided to the Customer in connection with this Agreement.

**“EU Personal Data”** means Customer Personal Data that is (a) originating from or located in the EEA, or (b) is Personal
Data of EEA data subjects, or any combination of the foregoing.

**“Fees”** means a payment for using the Platform under the Subscription as agreed between CentralCI and the Customer in the
Order Form and/or any other regular payments for using the Services as agreed herein or specific Order Forms.

**“Feedback”** means any suggestions, comments, bug reports, feedback, or suggested modifications for the CentralCI Platform
and/or Services from our Customers and Authorized Users or any other person.

**“IP Rights”** means any right, title, and interest in any registered and unregistered rights granted, applied for, or
otherwise now or hereafter in existence under or related to any patent, copyright, trademark, trade secret, database
protection or other intellectual property rights laws, and all similar or equivalent rights or forms of protection, in
any part of the world.

**“Personal Data”** means information about an identified or identifiable natural person that (a) can be used to identify,
contact, or locate a specific individual; (b) can be combined with other information that can be used to identify,
contact, or locate a specific individual; or (c) is defined as “personal data” or “personal information” by applicable
Data Protection Laws relating to the collection, use, storage or disclosure of information about an identifiable
individual.

**“Prohibited Content”** means any Customer Data (including source code, software, text, images or other information)
that: (a) is unlawful and/or promotes unlawful activities; (b) defames, harasses, abuses, threatens or incites violence
towards any individual or group; (c) is pornographic, discriminatory and/or otherwise victimizes and/or intimidates an
individual and/or group on the basis of religion, gender, sexual orientation, race, ethnicity, age and/or disability; (
d) is spam, is machine- or randomly-generated, constitutes unauthorized or unsolicited advertising, chain letters, any
other form of unauthorized solicitation and/or any form of lottery and/or gambling; (e) contains any Sensitive
Information; (f) contains and/or installs any viruses, worms, malware, Trojan horses and/or other content that is
designed or intended to disrupt, damage and/or limit the functioning of any software, hardware, and/or
telecommunications equipment and/or to damage and/or obtain unauthorized access to any data and/or other information of
any third party; (g) infringes upon any IP Rights of any party; (h) impersonates any person or entity, including any of
our employees or representatives; and/or (i) violates the privacy of any third party.

**“Platform”** means CentralCI’s proprietary cloud computing platform accessible via the Website (including the Console,
connected APIs, sample code; software libraries; command line tools; proofs of concept; templates, and any other
proprietary technologies) as made available by CentralCI to the Customer and its Authorized Users to use the Services
from time to time. For the avoidance of doubt, all references to the “Platform” in this Agreement also include
CentralCI’s Services and exclude any Third-Party Products.

**“Privacy Policy”** means CentralCI’s privacy policy, currently posted at CentralCI’s Website.

**“Sensitive Information”** means any personal data of a Customer’s end-users and/or any other Customer Data that is subject
to the U.S. Health Insurance Portability and Accountability Act (HIPAA), the Gramm–Leach–Bliley Act (GLBA) and/or Family
Educational Rights and Privacy Act (FERPA)).

**“Services”** means the set the products and services CentralCI makes available through the Platform and any other
proprietary technologies, software, or services offered by CentralCI in connection to any of those. The Services do not
include Third-Party Products.

**“Subscription”** means confirmation of the Customer’s enrollment and rights to access the Platform and/or use the Services
as described in the Order Form in exchange for payment of the applicable Fees during a twelve-month period or another
period indicated in the outstanding Order Form.

**“Taxes”** means any taxes, levies, duties, or similar governmental assessments of any nature, including, for example,
value-added, sales, use or withholding taxes, applicable in any jurisdiction.

**“Third-Party Products”** means any third-party products provided with, integrated with, or incorporated into the Platform.

**“Transferred”** and cognates means hosted, computed, stored, provided, uploaded, downloaded, created, generated,
submitted, distributed, imported, exported, posted and/or otherwise available through the Platform. “Website” means an
interface of the Platform compiled of all web documents (including images, CSS, and HTML files) made available
via https://centralci.com or its sub-domains or domains under other top domains that are owned by CentralCI.  


