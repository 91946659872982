---
title: "Cookie Policy"
description: "Cookie Policy"
created: 2025-01-01
updated: 2025-01-01
footer_region: Compliance
rank: 4
show_toc: true
---

# Cookie Policy

**Last updated:** 10 December 2024

This Cookie Policy aims to shed light on the types of cookies we use, their purpose, and most importantly, how they
impact your experience on our site. We believe in transparency and want to ensure that you are fully informed.

This Cookie Policy is an integral part of our [Terms of Service](terms-of-service) or 
[Master Services Agreement](master-services-agreement) (whichever shall be applicable) and our 
[Privacy Policy](privacy-policy). Any capitalized terms used (and not otherwise defined below) have their meanings
assigned to them in the Terms of Service. Any reference to “Users” in this document means a reference to our Customers,
Authorized Users, or visitors of the Website.

## What are cookies?

A cookie is a small file containing a string of characters that is sent to your computer when you visit a website. When
you visit the site again, the cookie allows that site to recognize your browser. Cookies may store user preferences and
other information.

Cookies provide a convenience feature to save you time, or tell the Web server that you have returned to a specific
page.

Cookies set by the website owner (in this case, CentralCI) are called “first party cookies”. Cookies set by parties
other than the website owner are called “third party cookies”. Third party cookies enable third party features or
functionality to be provided on or through the website (e.g. like advertising, interactive content and analytics). The
parties that set these third party cookies can recognise your computer both when it visits the website in question and
also when it visits certain other websites.

## Why do we use cookies?

We use first party and third party cookies for several reasons. Some cookies are required for technical reasons in order
for our Website to operate, and we refer to these as “essential” or “strictly necessary” cookies. Other cookies also
enable us to track and target the interests of our users to enhance the experience on our Website and Platform.

## What types of cookies do we use and how do we use them?

Essential website cookies: These cookies are strictly necessary to provide you with services available through our
Website.

Analytics and customisation cookies: These cookies collect information that is used either in aggregate form to help us
understand how our Website is being used, where it is being used and who is using it or how effective our marketing
campaigns are, or to help us customise our Website for you.

Advertising cookies: These cookies are used to make advertising messages more relevant to you. They perform functions
like preventing the same ad from continuously reappearing, ensuring that ads are properly displayed for advertisers, and
in some cases selecting advertisements that are based on your interests.

## How can I control cookies?

You have the right to decide whether to accept or reject cookies. You can exercise your cookie preferences by clicking
on the appropriate opt-out links provided below.

You can set or amend your web browser controls to accept or refuse cookies. If you choose to reject cookies, you may
still use our website though your access to some functionality and areas of our website may be restricted. As the means
by which you can refuse cookies through your web browser controls vary from browser-to-browser, you should visit your
browser’s help menu for more information.

Essential website cookies: because these cookies are strictly necessary to deliver the Website to you, you cannot refuse
them. You can block or delete them by changing your browser settings however, as described above.

## Do you serve targeted advertising?

Third parties may serve cookies on your computer or mobile device to serve advertising through our Website. These
companies may use information about your visits to this and other websites in order to provide relevant advertisements
about goods and services that you may be interested in. They may also employ technology that is used to measure the
effectiveness of advertisements. This can be accomplished by using cookies to collect information about your visits to
this and other sites in order to provide relevant advertisements about goods and services of potential interest to you.
The information collected through this process does not enable us or them to identify your name, contact details or
other personally identifying details unless you choose to provide these.

## How often will you update this Cookie Policy

We may periodically revise our cookie policy. Any changes will be displayed on this page, ensuring our users always have
access to the latest information on our cookie usage and data collection practices.

## Contact Us

If you have any questions or require further clarification about this Cookie Policy, please reach out to us at
privacy@centralci.com.