---
title: "Terms Of Service"
description: "Terms Of Service"
created: 2025-01-01
updated: 2025-01-01
footer_region: Compliance
rank: 2
show_toc: true
---

# Terms Of Service

**Last updated:** November 14, 2024

Welcome to CentralCI! We’re *delighted* to have you here. While the following terms may seem like a lot of legal jargon,
they play a crucial role in outlining the guidelines and regulations governing the use of CentralCI’s Products. It is
all very formal but they set out important information and guidelines for CentralCI’s Products. Please take a moment to
read through them.

Using CentralCI’s Products is completely optional. If you’re not comfortable with any of the terms outlined below, you
can choose not to register with CentralCI or access our Products.

## 1. Your Agreement with CentralCI

1.1 Your use of CentralCI Products is governed by this agreement (the “Terms”). “CentralCI” means CentralCI, Inc., a US
company, and its subsidiaries or affiliates involved in providing CentralCI Products. “You” and derivatives refer to
CentralCI’s Customer as defined in the “Definitions” section.

1.2 To use CentralCI Products, you must first agree to the Terms. You can agree to the Terms by registering for an
Account on CentralCI’s Platform. Your registration and access to the Platform or use of any CentralCI Products will be
deemed acceptance of the Terms. If you do not agree to the Terms, do not use or access CentralCI Products.

1.3 You may not use CentralCI Products if you are a person barred from receiving CentralCI Products under the laws of
the United States or other countries, including the country in which you are resident or from which you use CentralCI
Products. In addition, if you are 16 years old or younger, you may not use CentralCI Products or the CentralCI Website.
If you do register an Account or access or use CentralCI Products or the CentralCI Website, you affirm that you are over
the age of 16.

1.4 You agree that your use of CentralCI Products is not contingent on the delivery of any future functionality or
features or dependent on any oral or written public comments made by CentralCI or any of its affiliates regarding future
functionality or features.

## 2. Your Account and Use of CentralCI Products.

2.1 You must provide accurate and complete registration information any time you register to use CentralCI Products on
the CentralCI Platform and/or CentralCI Website. You are responsible for the security of your passwords and for any use
of your Account. If you become aware of any unauthorized use of your password or of your Account, you agree to notify
CentralCI immediately. You may not share your access credentials with any other individuals; any such sharing shall
constitute a material breach of these Terms.

2.2 If you use an email address provided by an organization you are affiliated with, you hereby represent that you have
authority to use that organization’s domain to sign up for CentralCI Products in your capacity as a member of that
organization. The organization, as the owner of the domain associated with your email address, may assume control over
and manage your use of CentralCI Products. In such a case, any references to “you” or “your” will pertain to the
applicable organization, and your organization’s designated administrator (your “Admin”) may (i) control and administer
the Account, including modifying and terminating your access, and (ii) access and process your data, including the
contents of your communications and files. If your organization is administering your use of CentralCIProducts, you must
direct your data subject requests and privacy inquiries to your Admin.

2.3 CentralCI will grant administrative privileges to one Admin for the purpose of setting up access credentials for
other Authorized Users. Authorized Users may not share access credentials with any other individuals; any such sharing
shall constitute a material breach of the Terms. The Admin must de-authorize access of any employee or person who is no
longer an Authorized User within fifteen (15) days thereof. CentralCI may request the Admin to confirm whether email
addresses for the Authorized Users are active and in good standing.

2.4 You acknowledge that to the extent your Authorized Users are invited to access a third-party’s Projects, any access
to those Projects as well as any Content the Authorized Users submit will be under the sole control of that other
Customer.

2.5 You shall control who you share Projects with (including making Projects public). CentralCI has no liability for how
others may access or use your Project as a result of your or your Authorized Users’ decision to share a Project.

2.6 Your use of CentralCI Products must comply with all applicable laws, regulations, and ordinances, including any laws
regarding the export of data or software and sanctions. You or the organization you represent shall be fully responsible
for your Authorized Users’ compliance with the Terms and all use of CentralCI Products.

2.7 You agree not to: (a) access (or attempt to access) the CentralCI Platform or administrative interface of CentralCI
Products by any means other than through your Account or other interface that is provided by CentralCI in connection
with CentralCI Products, unless you have been specifically allowed to do so in a separate written agreement with
CentralCI, or (b) engage in any activity that interferes with or disrupts CentralCI Products (or the servers and
networks which are connected to CentralCI Products).

2.8 You may not Transfer to or through the Platform any information or materials that are unlawful or injurious, or that
contain, transmit, or activate any Harmful Code or are deemed or could be deemed offensive, harassing, inappropriate, or
discriminatory.

2.9 You may access the CentralCI Platform and/or use CentralCI Products to manage your Projects and perform all the
related activities, including running queries, and compute scaling operations. You may not access the Platform or use
CentralCI Products for the purpose of bringing an intellectual property infringement claim against CentralCI or to
create a product or service competitive with CentralCI Products, or for any unlawful activities.

## 3. Privacy , Personal Data, and Customer Data

3.1 The use of CentralCI Products and Website shall be subject to the [privacy policy](privacy-policy) and terms 
of the Data Processing Agreement (available at 
[www.centralci.com/data-processing-agreement](www.centralci.com/data-processing-agreement)) (the “DPA”) are hereby 
incorporated by reference and will apply to Customer Personal Data. The DPA sets out how we will process Customer 
Personal Data on your behalf in connection with the Services provided to you under this Agreement. 
We will maintain commercially appropriate administrative, physical, and technical safeguards to protect Customer 
Personal Data as described in the DPA, including our Security Measures in Annex 2 of our DPA.

3.2 Customer will ensure that its Customer Data, and its use of such Customer Data, complies with this Agreement and any
applicable law. Customer is responsible for properly configuring and using the Services and taking its own steps to
maintain appropriate security, protection, and backup of Customer Data.

3.3 We do not knowingly process or collect any Sensitive Information from you or on your behalf and if you choose to
Transfer Sensitive Information using our Products you do so at your own risk.

3.4 The Customer (for itself and all of its Authorized Users) grants CentralCI the right to use Customer Data solely as
necessary (a) to provide, maintain, improve, promote, protect, and update the Services and the Platform; (b) to prevent
or address service, security, support, or technical issues related to the Services and the Platform; (c) as required by
law or as permitted by the Privacy Policy or Data Processing Agreement; or (d) as expressly permitted in writing by the
Customer.

3.5 Notwithstanding the foregoing, CentralCI may disclose the Customer Data, including any Confidential Information, as
required by applicable laws and requirements or regulatory body or governmental authority. CentralCI shall give the
Customer a prompt notice, if permitted by applicable law, of any such legal or governmental request. CentralCI will use
all commercially reasonable efforts to cooperate with the Customer in any effort to seek a protective order or otherwise
to contest such required disclosure, at the Customer’s expense.

3.6 We may transfer Customer Data (including Customer Personal Data) to the United States in connection with the
Services. To the extent we process Personal Data that is subject to the protection of European Data Protection Laws (as
defined in the DPA) in the United States, we will process such Personal Data in accordance with the EU-U.S. Data Privacy
Framework, the UK Extension to the EU-U.S. Data Privacy Framework, and the Swiss-U.S. Data Privacy Framework (
collectively, the “Data Privacy Framework”) as set out in our DPA. For more information about the Data Privacy
Framework, please see our Privacy Policy.

3.7 Unless expressly stated herein or in the applicable Order Form, CentralCI will have no responsibility or liability
for any Customer Data Transferred through the Platform, nor for any actions taken by the Customer or the Authorized
Users as a result of Customer Data Transfers. CentralCI shall not bear any liability or responsibility in relation to
the accuracy of the Customer Data, nor does CentralCI endorse any opinion contained in any of the Content Transferred
through the Platform.

## 4. Fees for the use of CentralCI Products

4.1 CentralCI Products will be provided according to the Plan selected in the specific Order. Your subscription to any
Plan will automatically-renew for the same term as the previous Subscription Term unless you cancel your Plan 14
business days in advance of the applicable renewal date or as otherwise agreed in the Order.

4.2 The Fees for CentralCI Products will depend on your selected Plan as set forth in the Order. The Fees will be
calculated based on your usage of CentralCI Products within a certain billing cycle without offset or deduction at the
cadence identified in the Order (e.g., monthly or annually).

4.3 All Fees, refunds and Taxes due hereunder will be paid in U.S. dollars. Payment obligations are non-cancelable and,
except as expressly stated in these Terms, your payments of the Fees are non-refundable.

4.4 The billing cycle will start on the first day of the month or first date of your Subscription Term (whatsoever
applies) and end on the last day of each calendar month unless otherwise agreed in the applicable Order.

4.5 CentralCI will calculate your usage of CentralCI Products based on rates and certain consumption units, described
here, agreed in the specific Order to determine the Fees during the prior billing period and then invoice you on or
about the 1st day of the following billing period. Unless you and CentralCI agreed in the Order otherwise, all Fees are
due on the first day of the month following the billing cycle and will be automatically charged to your card on or about
the invoice date. For example, your usage for the month of January will be calculated and invoiced on or about February
1; and your credit card will be charged simultaneously.

4.6 You acknowledge and agree that CentralCI will automatically charge your payment card or other payment instrument on
record with CentralCI in connection with your use of CentralCI Products in accordance with the billing terms in effect
when Fees are due and payable.

4.7 You represent and warrant to CentralCI that all of your payment information is true and that you are authorized to
use the payment instrument provided in connection with your Account. You will promptly update your Account information
with any changes (for example, a change in your billing address or credit card expiration date) that may occur.

4.8 If you fail to make any payment when due, and you have not notified CentralCI in writing to ar@centralci.com within
seven (7) days of the payment becoming due and payable that the payment is subject to a good faith dispute, without
limiting CentralCI’s other rights and remedies: (i) CentralCI may charge interest on the undisputed past due amount at
the rate of 1.5% per month, calculated daily and compounded monthly or, if lower, the highest rate permitted under
applicable law; (ii) if CentralCI requests, you shall reimburse CentralCI for all costs due for storage of your Projects
and other Content, and other reasonable costs incurred by CentralCI in collecting any late payments or interest,
including attorneys’ fees, court costs, and collection agency fees; and (iii) if such failure continues for seven (7)
days or more, CentralCI may suspend your Account and your Authorized Users’ access to any portion or all of CentralCI
Products or downgrade you to the Free Tier until such amounts are paid in full. If non-payment is a result of a dispute,
CentralCI may suspend services and access to CentralCI Products until the dispute is resolved and payment is made, if
applicable, upon prior notice to you.

4.9 If your Fees remain due for more than sixty (60) days following the applicable payment due date, CentralCI has the
right to deactivate your access to the CentralCI Platform completely and permanently delete any and all of your Projects
and other Content which may be contained in connection with your Account. Any notices provided by CentralCI regarding
downgrading to the Free Tier, suspension or termination of your Account or deletion of your Data/Projects is a courtesy
and is not required. You are responsible for maintaining your Account in good standing, including payment of all Fees
when due.

4.10 You may upgrade or downgrade to any other Plan level that CentralCI offers or cancel your Order at any time during
your Subscription Term, provided that a cancellation and downgrade will not be effective until the next billing period
and full payment of the Fees due for the previous billing period have been received by CentralCI.

4.11 CentralCI may change its pricing model, Fees, and payment terms at its discretion, provided, however, that such
changes will not take effect for you until the start of the next billing period. CentralCI will provide written notice
to you of any changes to the Fees that affect CentralCI Products purchased by you. Your continued use of such CentralCI
Products after the change becomes effective constitutes your acceptance of the modified Fees and payment terms and
agreement to pay the changed amounts.

4.12 You will pay all applicable Taxes. When CentralCI has the legal obligation to pay or collect Taxes, the appropriate
amount shall be paid by you directly to CentralCI. If all or any part of any payment owed to CentralCI under these Terms
is withheld, based upon a claim that such withholding is required pursuant to the tax laws of any country or its
political subdivisions and/or any tax treaty between the U.S. and any such country, such payment shall be increased by
the amount necessary to result in a net payment to CentralCI of the amounts otherwise payable under these Terms. You
will reimburse CentralCI any pre-approved and agreed upon costs.

4.13 California Residents. The provider of services is set forth herein. If you are a California resident, in accordance
with Cal. Civ. Code §1789.3, you may report complaints to the Complaint Assistance Unit of the Division of Consumer
Services of the California Department of Consumer Affairs by contacting them in writing at 1625 North Market Blvd.,
Suite N 112 Sacramento, CA 95834, or by telephone at (800) 952-5210 or (916) 445-1254.

## 5. Your Control and Responsibility

5.1 You have and will retain sole responsibility for: (i) all Content, including the Customer Personal Data and
Sensitive Information, and its use; (ii) all information, instructions, and materials provided by or on behalf of you in
connection with CentralCI Product(s); (iii) your information technology infrastructure, including computers, software,
databases, electronic systems (including database management systems), and networks, whether operated directly by you or
through the use of third-party services (“Customer Systems”); (iv) the security and use of your or any Authorized User
access credentials; and (v) all access to and use of CentralCI Product(s) directly or indirectly by or through the
Customer Systems or your access credentials, with or without your knowledge or consent, including all results obtained
from, and all conclusions, decisions, and actions based on, such access or use.

5.2 You and all your Authorized Users shall use CentralCI Products in compliance with all applicable laws, the Terms and
the Documentation (as applicable). You and/or any Authorized User’s failure to maintain Projects within the support
perimeter (as specified in the Documentation) may result in Platform’s failure for which CentralCI is not responsible.
Any customization, copies, and use of any additional software with the CentralCI Platform and/or CentralCI Products may
result in the instance falling outside the support perimeter and causing the Platform’s failure for which CentralCI is
not responsible.

5.3 You shall use CentralCI in compliance with all applicable laws, the Documentation, the Acceptable Use Policy, and
the Terms. You are prohibited from: (a) modifying and/or making derivative works of, disassembling, extracting, reverse
compiling and/or reverse engineering the source code or any part of the Platform and/or CentralCI Product(s); (b)
reselling, offering, selling, renting, leasing, distributing, assigning and/or otherwise commercially exploiting
CentralCI Products for any purposes, in whole or in part, except where specifically permitted by CentralCI, in
particular, you and all of your Authorized Users are prohibited from offering CentralCI Products, use CentralCI APIs and
Connectors as defined in the Documentation in any format on a white-label basis for commercial purposes; (c) using
and/or accessing CentralCI Products in order to build a similar and/or competitive website, platform, product and/or
service or for other benchmarking or competitive purposes; (d) framing or otherwise incorporating CentralCI Product(s),
as part of another website or service; (e) removing and/or destroying any copyright notices and/or other proprietary
markings contained in the CentralCI Platform and/or CentralCI Products; (f) taking any action that imposes or may
impose (as determined by CentralCI’s sole and absolute discretion) an unreasonable and/or disproportionately large load
on CentralCI’s and/or our third-party providers’ infrastructure; (j) exceeding any Usage Limitations and service
capacity available in your Order(l) promoting and/or providing information about illegal activities and/or physical harm
and/or injury to any group, individual, institution and/or property; and (m) transferring through the CentralCI Website,
Platform, and/or CentralCI Product(s) any Prohibited Content.

5.4 You understand that the operation of the CentralCI Platform, including your Projects and other Content, may
involve (a) transmissions over various networks; (b) changes to conform and adapt to technical requirements of
connecting networks or devices and (c) transmission to CentralCI’s third-party vendors and hosting partners to provide
the necessary hardware, software, networking, storage, and related technology required to operate and maintain the
CentralCI Platform and/or CentralCI Products. Accordingly, you acknowledge that you bear sole responsibility for
adequate security, protection, and backup of your Projects and other Content. CentralCI will have no liability to you
for any unauthorized access or use of the CentralCI Platform and/or CentralCI Products that is attributable, in whole or
in part, to an insecurity in your Account, malware or malicious content in your Project or other Content, or any
corruption, deletion, destruction, or loss of any your Projects and other Content.

## 6. Intellectual Property Ownership

6.1 You acknowledge that, as between you and CentralCI, CentralCI owns all right, title, and interest, including all
intellectual property rights, in and to the CentralCI IP and, with respect to Third-Party Products, the applicable
third-party providers own all right, title, and interest, including all intellectual property rights, in and to the
Third-Party Products.

6.2 Open-source software licenses for components of CentralCI Products released under an open-source license constitute
separate written agreements. To the limited extent that the open-source software licenses expressly supersede these
Terms, the open-source licenses govern your agreement with CentralCI for using the components of the CentralCI Platform
and/or CentralCI Product(s) released under an open-source license.

6.3 CentralCI acknowledges that, as between CentralCI and you, you own all rights, titles, and interests, including all
intellectual property rights, in and to the Content. You hereby grant to CentralCI a non-exclusive, royalty-free,
worldwide license to reproduce, distribute, and otherwise use and display the Content and perform all acts with respect
to the Content as may be necessary for CentralCI to provide CentralCI Products to you. You may export Content anytime
through the features and functionalities made available via the CentralCI Platform and/or CentralCI Products.

6.4 If you or any of your teammates, employees or contractors send or transmit any communications or materials to
CentralCI by mail, email, public repositories, telephone, social media, or otherwise, suggesting or recommending changes
to the CentralCI IP, including without limitation, new features or functionality relating thereto, or any comments,
questions, suggestions, or the like (the “Feedback”), CentralCI will be deemed to own the Feedback, and in all cases, is
free to fully exploit and use such Feedback irrespective of any other obligation or limitation between the parties
governing such Feedback so long as CentralCI does not identify you or such person as the source of the Feedback without
your or such person’s prior approval.

6.5 CentralCI shall have the right to collect and analyze data and other information relating to the provision, use, and
performance of various aspects of CentralCI Products and related systems and technologies (excluding Content and data
derived therefrom), and CentralCI will be free (during and after the term hereof) to (i) use such information and data
to improve and enhance CentralCI Products and for other development, diagnostic, analytical and corrective purposes in
connection with CentralCI Products and other CentralCI offerings, and (ii) fully exploit, use and disclose such data
solely in aggregate or other de-identified form in connection with its business (the “Aggregated Data”).

## 7. Service Limitations and Modifications

7.1 CentralCI will not materially and adversely modify the functionality (e.g., tools, functions, and features) of the
CentralCI Platform agreed with Customers per existing Orders. CentralCI is constantly innovating in order to provide the
best possible experience for its users. CentralCI reserves the right to change the functionality of the CentralCI
Platform and/or CentralCI Products available under a specific Plan. Any of these changes will be made for the Customer
starting from a new Subscription Term after the expiration of the current billing period, provided prior notice of
upcoming changes in the functionality of the Platform available under a specific Plan has been given.

7.2 CentralCI will make commercially reasonable efforts to keep the Platform and CentralCI Products operational.
However, certain technical difficulties or maintenance may, from time to time, result in temporary interruptions. To the
extent permissible under any applicable law, CentralCI reserves the right at any time to modify or discontinue,
temporarily or permanently, the functionality of the CentralCI Platform and/or CentralCI Products for any technical or
operational reasons that might adversely affect our Customers.

7.3 Such changes to the Platform and/or CentralCI Products might be made, depending on the circumstances, with or
without notice and without liability to you for any interruption, modification, or discontinuation of the CentralCI
Platform and/or CentralCI Products or any functionality thereof, except where prohibited by law.

7.4 You understand, agree, and accept that CentralCI has no obligation to maintain, support, upgrade, or update the
CentralCI Platform or CentralCI Products, or to provide all or any specific content or information through the Platform.
This section will be enforced to the extent permissible by any applicable law.

## 8. Deactivation and deletion of your Account

8.1 You have the right to deactivate/delete your Account at any time by sending a cancellation request by creating an
in-app support ticket in your Account. Such deactivation/deletion will be effective at the start of the next billing
cycle or renewal period. You must delete all active projects before CentralCI can deactivate/delete your Account.

8.2 You agree that CentralCI, in its sole discretion and for any violation of the Terms, acceptable use policy, or
applicable laws, may suspend or deactivate/delete your Account or any part thereof. You agree that any termination of
your access to the CentralCI Platform and/or CentralCI Products may be without prior notice, and you agree that
CentralCI will not be liable to you or any third party for such termination.

8.3 CentralCI may also deactivate/delete your Account and terminate these Terms at any time by providing thirty (30)
days prior notice to the administrative email address associated with your Account. If CentralCI terminates these Terms
without cause, CentralCI will bill and invoice you for CentralCI Products on the last date of subscription indicated in
the relevant termination notice. If you prepaid any Fees, CentralCI will refund the pro-rated, unearned portion of any
amount that you have prepaid to CentralCI for CentralCI Products.

8.4 You are solely responsible for exporting your Projects and Content from the CentralCI Platform before the
deactivation/deletion of your Account for any reason. If we suspend, deactivate, or delete your Account, we will use
commercially reasonable efforts (without any duty or obligation) to provide you an opportunity to retrieve your Content.

8.5 You further acknowledge that CentralCI reserves the right to deactivate/delete Accounts that are inactive for an
extended period of time and the right to modify or discontinue, temporarily or permanently, CentralCI Products (or any
part thereof). All of your Projects and Content on the Platform (if any) may be permanently deleted by CentralCI upon
deletion of your Account in its sole discretion.

8.5 Upon any termination of your access to the Platform and/or CentralCI Products or your Account, these Terms will also
terminate, but in all cases, Sections 9, 10, 11, 12, 14 and 17, shall survive and continue to be effective after these
Terms are terminated.

## 9. Warranty Disclaimer

CENTRALCI PRODUCTS ARE PROVIDED “AS IS” AND CENTRALCI HEREBY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED,
STATUTORY, OR OTHERWISE. CENTRALCI SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT, AND ALL WARRANTIES ARISING FROM THE COURSE OF DEALING, USAGE, OR TRADE
PRACTICE. CENTRALCI MAKES NO WARRANTY OF ANY KIND THAT THE CENTRALCI PRODUCTS, OR ANY PRODUCTS OR RESULTS OF THE USE
THEREOF, WILL MEET YOUR OR ANY OTHER PERSON’S REQUIREMENTS, OPERATE WITHOUT INTERRUPTION, ACHIEVE ANY INTENDED RESULT,
BE COMPATIBLE OR WORK WITH ANY SOFTWARE, SYSTEM OR OTHER SERVICES, OR BE SECURE, ACCURATE, COMPLETE, FREE OF HARMFUL
CODE, OR ERROR-FREE.

## 10. Limitation of Liability

10.1 YOU HEREBY EXPRESSLY UNDERSTAND AND AGREE THAT CENTRALCI, ITS SUBSIDIARIES AND AFFILIATES, AND ITS LICENSORS SHALL
NOT BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL CONSEQUENTIAL OR EXEMPLARY DAMAGES WHICH MAY BE
INCURRED BY YOU, HOWEVER, CAUSED AND UNDER ANY THEORY OF LIABILITY. THIS SHALL INCLUDE, BUT NOT BE LIMITED TO, ANY LOSS
OF PROFIT (WHETHER INCURRED DIRECTLY OR INDIRECTLY), ANY LOSS OF GOODWILL OR BUSINESS REPUTATION, ANY LOSS OF DATA
SUFFERED, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR OTHER INTANGIBLE LOSS. UNLESS OTHERWISE IT IS
EXPRESSLY STATED IN THESE TERMS, CENTRALCI’S MAXIMUM AGGREGATE LIABILITY TO YOU OR ANY PARTY OR PERSON FOR DAMAGES OF
ANY KIND THAT YOU OR ANY PARTY SUFFERS IN CONNECTION WITH CENTRALCI PRODUCTS OR OFFERINGS OR THESE TERMS IS LIMITED TO
THE AMOUNT OF THE FEES PAID BY YOU TO CENTRALCI IN THE SIX (6) MONTH PERIOD IMMEDIATELY PRECEDING THE DATE OF THE LAST
EVENT GIVING RISE TO THE LIABILITY.

10.2 THE LIMITATIONS ON CENTRALCI’S LIABILITY TO YOU IN PARAGRAPH 11.1 ABOVE SHALL APPLY WHETHER OR NOT YOU OR CENTRALCI
HAS BEEN ADVISED OF OR SHOULD HAVE BEEN AWARE OF THE POSSIBILITY OF ANY SUCH LOSSES ARISING AND SHALL IN ALL CASES APPLY
TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW.

## 11. Indemnification

You agree to hold harmless, defend and indemnify CentralCI, and its subsidiaries, affiliates, officers, agents,
employees, advertisers, licensors, suppliers or partners (collectively “CentralCI and Partners”) from and against any
third party claim arising from or in any way related to (a) your breach of the Terms or Acceptable Use Policy, (b) your
use of CentralCI Products, (c) your violation of applicable laws, rules or regulations in connection with CentralCI
Products, or (d) your Projects or other Content, including any liability or expense arising from all claims, losses,
damages (actual and consequential), suits, judgments, litigation costs, and attorneys’ fees, of every kind and nature.
In such a case, CentralCI will provide you with written notice of such claim, suit, or action. You cannot and do not
have authorization to settle any claims, suits, or actions with regard to CentralCI without CentralCI’s prior written
consent.

## 12. Third-Party Products and AI

12.1 CentralCI may from time to time make Third-Party Products available to you or CentralCI may allow for certain
Third-Party Products to be integrated with CentralCI Products to allow for the transmission of Content from such
Third-Party Products into CentralCI Products. For purposes of this Agreement, such Third-Party Products are subject to
their own terms and conditions. If you do not agree to abide by the applicable terms for any such Third-Party Products,
then you should not install or use such Third-Party Products. By authorizing CentralCI to transmit Content from
Third-Party Products into CentralCI Product(s), you represent and warrant to CentralCI that you have all right, power,
and authority to provide such authorization.

12.2 You acknowledge and agree that CentralCI is not liable for any loss or damage which may be incurred by you or as a
result of the services, content, or availability of those Third-Party Products, or as a result of any reliance placed by
you on the completeness, accuracy or existence of any advertising, products or other materials on, or available from,
such websites or resources.

12.3 By agreeing to these Terms, you acknowledge and consent to the use of artificial intelligence (AI) technologies
within CentralCI Products. These AI technologies may be utilized to enhance user experience and improve product
functionality. You understand that the utilization of AI may involve the collection and analysis of user data to tailor
features and content. Additionally, you acknowledge that the performance of AI functionalities may evolve over time as
the technology advances, and we reserve the right to update or modify these features accordingly. Your continued use of
CentralCI Products constitutes acceptance of any such updates or modifications.

## 13. Technical Previews

13.1 If you receive access to CentralCI Products or features, technologies, and services that are not yet generally
available on a free or paid basis, including, but not limited to, any products, services, or features labeled as an
alpha, beta, technical preview or early access offering, or access and use CentralCI Products available in regions that
are not generally available, including, but not limited to, any regions identified by CentralCI as “alpha”, “beta”,
“preview”, “pre-release”, or “experimental” (the “Technical Preview”), You must comply with all terms related to any
Technical Preview as posted on the CentralCI Website and/or the CentralCI Platform.

13.2 CentralCI may add or modify terms, including lowering or raising any usage limits, related to access to or use of
any Technical Preview at any time. Technical Previews may not be covered by customer support and/or service level
agreements at CentralCI’s sole discretion. CentralCI may change or discontinue Technical Previews at any time without
notice. Technical Previews are not ready for general commercial release and may contain bugs, errors, defects, or
harmful components. CentralCI’s Product(s) in Technical Preview may be inoperable, incomplete or include features that
CentralCI may never release, and their features and performance information are CentralCI’s IP.

**13.3 Notwithstanding anything else in these Terms, CentralCI provides Technical Previews “AS IS” with no warranty,
indemnity, or support. Except to the extent prohibited by law, CentralCI disclaims all warranties, including any implied
warranties of merchantability, satisfactory quality, fitness for a particular purpose, non-infringement, or quiet
enjoyment, and any warranties arising out of any course of dealing or usage of trade. You should not rely on CentralCI
Products in Technical Preview in any manner and your use of such is at your own risk. CentralCI’s aggregate liability
for any Technical Preview will not exceed US$10.**

## 14. Changes to the Terms

14.1 CentralCI may make changes to the Terms from time to time. If we change the Terms in any substantive way, we will
notify Customers before the changes take effect, during which period you may reject the changes by terminating your
Order or deactivating your Account.

14.2 You understand and agree that if you access the CentralCI Platform and/or use CentralCI Products after the date on
which the Terms have changed, CentralCI will treat your use as acceptance of the updated Terms.

## 15. Publicity

CentralCI may identify you as a user of CentralCI Products and may use your name, logo, and other trademarks in
CentralCI’s customer list, press releases, blog posts, advertisements, social media, and Website (and all use thereof
and goodwill arising therefrom with regard to your use of the CentralCI Website or CentralCI Products shall inure to the
sole and exclusive benefit of CentralCI, unless you withdraw your consent by sending email to privacy@centralci.com).
Otherwise, neither party may use the name, logo, or other trademarks of the other party for any purpose without the
other party’s prior written approval.

## 16. Miscellaneous

16.1 Except to the extent you and CentralCI have entered into a separate written agreement that is expressly intended to
supersede these Terms either in whole or in part, the Terms constitute the whole legal agreement between you and
CentralCI and govern your use of CentralCI Products and the Website (excluding any services which CentralCI may provide
to you under a separate written agreement), and completely replace any prior agreements between you and CentralCI in
relation to CentralCI Products and the Website.

16.2 Subject to earlier termination as permitted herein, the term of these Terms will commence on the earlier of your
acceptance of these Terms or your use of CentralCI Products and will continue for as long as CentralCI Products are
being provided to you under these Terms.

16.3 There are no third-party beneficiaries to these Terms. The parties are independent contractors, and nothing in
these Terms creates an agency, partnership, or joint venture.

16.4 You agree that CentralCI may provide you with notices, including those regarding changes to the Terms, by email or
in-app postings in your Account or the CentralCI Platform. By providing CentralCI your email address, you consent to our
using the email address to send you any notices required by law in lieu of communication by postal mail.

16.5 You agree that if CentralCI does not exercise or enforce any legal right or remedy that is contained in the Terms (
or which CentralCI has the benefit of under any applicable law), this will not be taken to be a formal waiver of
CentralCI’s rights and that those rights or remedies will still be available to CentralCI.

16.6 CentralCI shall not be liable for failing or delaying the performance of its obligations resulting from any
condition beyond its reasonable control, including but not limited to, governmental action, acts of terrorism,
pandemics, earthquake, fire, flood or other acts of God, labor conditions, power failures, and Internet disturbances.

16.7 The Terms, and your relationship with CentralCI under the Terms, shall be governed by the laws of the State of
Delaware without regard to its conflict of laws provisions. You and CentralCI agree to submit to the exclusive
jurisdiction of the courts located within the State of Delaware to resolve any legal matter arising from the Terms.

16.8 Class Action Waiver. YOU AND CENTRALCI EACH AGREE THAT ANY DISPUTE RESOLUTION PROCEEDING WILL BE CONDUCTED ONLY ON
AN INDIVIDUAL BASIS AND NOT IN A CLASS, CONSOLIDATED OR REPRESENTATIVE ACTION. You and CentralCI each agree that such
proceeding shall take solely by means of judicial reference pursuant to California Code of Civil Procedure section 638.

16.9 Opt-Out. You have the right to opt-out and not be bound by the class action waiver provisions set forth above by
sending written notice of your decision to opt-out to: privacy@centralci.com with the subject line “CLASS ACTION WAIVER
OPT-OUT”. The notice must be sent within thirty (30) days of your first use of CentralCI Products, otherwise you shall
be bound to settle any disputes in accordance with these Terms providing for binding legal proceedings on an individual
basis. If you opt-out of these arbitration provisions, our third-party providers also will not be bound by them.

16.10 You may not assign any of your rights or obligations under these Terms, whether by operation of law or otherwise,
without the prior written consent of CentralCI (not to be unreasonably withheld). CentralCI may assign, delegate or
transfer its rights or interest, in whole or in part, under and in connection with this Agreement, in which case,
CentralCI will provide you notice.

16.11 You agree to comply with all relevant U.S. and foreign export, import and sanction related Laws and regulations in
using CentralCI Products. You (a) represent and warrant that you are not listed on any U.S. government list of
prohibited or restricted parties or located in (or a national of) a country that is subject to a U.S. government embargo
or that has been designated by the U.S. government as a “terrorist supporting” country, (b) agree not to access or use
CentralCI Products in violation of any U.S. export embargo, sanction, prohibition or restriction, (c) will not submit to
CentralCI Products any information controlled under the U.S. International Traffic in Arms Regulations and (d) are an
authorized recipient of CentralCI Products and Services in an authorized geographic location.

## Definitions

**“Account”** means a browser-based graphical interface of the Platform that gives access to CentralCI Products as well as
Customer’s Projects, effective Orders, and billing information.

**“Authorized User”** means an authenticated individual, including any Customer’s employee, agent, contractor, or employee,
business partners, contractors, affiliates, representatives or whosoever the Admin granted permission to access Account
and/or the Project(s) and/or use CentralCI Products via pre-approved emails.

**“Content”** means information, including but not limited to, measurable data units, branches, database instances, source
code, files, folders, texts, design layouts, pictures, video and other images, audio materials, graphics, document or
data files, messages and other communications, personalization settings and other information and/or content, which is
or may be Transferred through the CentralCI Platform by or on behalf of any Customer, including the Content generated by
any Authorized User’s end-users, and any computational results that the Customer or any Authorized User may derive from
the foregoing through their use of CentralCI Products; provided that, for purposes of clarity, does not include
Aggregated Data.

**“Customer”** means an individual, legal entity, or organization that has accepted these Terms and registered an Account in
order to access the CentralCI Platform and/or use CentralCI Products subject to any available Plan.

**“Customer Data”** means jointly the Content, the Customer Personal Data as well as any other information that the Customer
and/or its Authorized Users Transfers to CentralCI in connection with the use of the Services.

**“Customer Personal Data”** means Personal Data that CentralCI processes as a data processor for the Customer for the
purpose of providing the Services. The Customer Personal Data includes Personal Data that the Customer and/or any
Authorized User Transfers through the Platform in connection with its use of the Services.

**“Data Protection Laws”** means all laws and regulations, including laws and regulations of the United States of America,
the European Union (the “EU”), the European Economic Area (the “EEA”) and their member states, Switzerland and the
United Kingdom, applicable to the processing of the Personal Data under these Terms.

**“Documentation”** means CentralCI’s usage guidelines and standard technical documentation relating to CentralCI Product(s)
available at https://centralci.com/ (for the avoidance of doubt this
includes https://centralci.com/docs/security/acceptable-use-policy).

**“Fees”** means a payment for using the Platform and/or any other regular payments for using the CentralCI Products as
agreed between CentralCI and the Customer in the specific Order.

**“Free Tier”** means CentralCI Products made available to users free of charge which is subject to the Usage Limitations
set forth here.

**“Harmful Code”** means any software, hardware, or other technology, device, or means, including any virus, worm, malware,
or other malicious computer code, the purpose or effect of which is to permit unauthorized access to, or to destroy,
disrupt, disable, distort, or otherwise harm or impede in any manner any (i) computer, software, firmware, hardware,
system, or network; or (ii) any application or function of any of the foregoing or the security, integrity,
confidentiality, or use of any data processed thereby.

**“CentralCI IP”** means CentralCI Products, the Documentation, and any and all technology, processes, methodologies,
formats, programs, know-how, and all intellectual property related thereto, including all improvements, enhancements,
extensions of or to the foregoing relating to the provision, support, development, and enhancement of CentralCI Products
and their related components. For the avoidance of doubt, CentralCI IP includes Aggregated Data and any information,
data, or other content derived from CentralCI’s Products but does not include Content.

**“CentralCI Products”** means the products, offerings, and services CentralCI makes available to its Customers, partners
and their end-users, including the Website, the CentralCI Platform, the CentralCI API, CentralCI add-ons, and any other
proprietary technologies, software, or services offered by CentralCI in connection to any of the foregoing.

**“Order”** means any (i) CentralCI-provided purchase order, order form, or other ordering document entered into by the
Parties that incorporates these Terms by reference; or (ii) if Customer registered for CentralCI Products through
CentralCI’s online ordering process, the results of such online ordering process.

**“Plan”** means a set of Usage Limitations, tools, features, and functionality that form a basis for the Order. Details of
available Plans are https://centralci.com/docs/introduction/plans.

**“Subscription Term”** means the time period identified on the Order during which Customer and its Authorized Users may
access and use CentralCI Products.

**“Personal Data”** means information about an identified or identifiable natural person that (a) can be used to identify,
contact or locate a specific individual; (b) can be combined with other information that can be used to identify,
contact or locate a specific individual; or (c) is defined as “personal data” or “personal information” by applicable
Data Protection Laws relating to the collection, use, storage or disclosure of information about an identifiable
individual.

**“Platform”** means CentralCI’s proprietary cloud computing platform accessible via the Account (including connected APIs,
sample code; software libraries; command line tools; proofs of concept; templates and any other proprietary
technologies) as made available by CentralCI to the Customers and their Authorized Users to use CentralCI Products from
time to time. For the avoidance of doubt, all references to the “Platform” in these Terms also include CentralCI’s
Products and vice versa, and excludes any Third-Party Products.

**“Project”** means a collection of web instances, worker instances, database instances, roles, Content, 
other resources and settings that Authorized Users maintain on the CentralCI Platform.

**“Prohibited Content”** means any Content (including source code, software, text, images or other information) that: (a) is
unlawful and/or promotes unlawful activities; (b) defames, harasses, abuses, threatens or incites violence towards any
individual or group or could be deemed to do the foregoing by CentralCI; (c) is pornographic, discriminatory and/or
otherwise victimizes and/or intimidates an individual and/or group on the basis of religion, gender, sexual orientation,
race, ethnicity, age and/or disability; (d) is spam, is machine- or randomly-generated, constitutes unauthorized or
unsolicited advertising, chain letters, any other form of unauthorized solicitation and/or any form of lottery and/or
gambling; (e) contains any Sensitive Information; (f) contains and/or installs or transmits in any manner any viruses,
worms, malware, Trojan horses and/or other content that is designed or intended to disrupt, damage and/or limit the
functioning of any software, hardware, and/or telecommunications equipment and/or to damage and/or obtain unauthorized
access to any data and/or other information of any third party; (g) infringes upon any intellectual rights of any
party; (h) impersonates any person or entity, including any of our employees, personnel or representatives; and/or (i)
violates the privacy of any third party.

**“Sensitive Information”** means any Personal Data, including personal data of your end-users and/or any other personal
data subject to the U.S. Health Insurance Portability and Accountability Act (HIPAA), the Gramm–Leach–Bliley Act (GLBA),
and/or the Family Educational Rights and Privacy Act (FERPA).

**“Third-Party Products”** means any third-party products provided with, integrated with, or incorporated into CentralCI
Products.

**“Taxes”** means any taxes, levies, duties, or similar governmental assessments of any nature, including, for example,
value-added, sales, use or withholding/backup withholding, and the like, applicable in any jurisdiction.

**“Transferred”** and cognates means hosted, computed, stored, provided, uploaded, downloaded, created, generated,
submitted, distributed, imported, exported, transmitted posted and/or otherwise available through the CentralCI Platform
and/or CentralCI Products.

**“Usage Limitations”** means the usage limitations set forth in these Terms and the respective Orders, including without
limitation any limitations on certain consumption units, described here, number of Authorized Users (if any), and the
applicable product, pricing, and support tiers.

**“Website”** means an interface of CentralCI Products compiled of all web
documents (including images, CSS, and HTML files) made available via https://centralci.com or its sub-domains or
domains under other top domains that are owned by CentralCI.