import type { Component } from "svelte"
import { sortBy } from 'sort-by-typescript';

type DocFrontMatter = {
  title: string
  description: string
  created: Date
  updated: Date
  show_toc: boolean
  toc_depth?: number
  rank?: number
  footer_region?: string
}

type RawDocument = {
  default: Component
  metadata: DocFrontMatter
}

const defaults = {
  toc_depth: 3,
  rank: 999,
  footer_region: "",
}

export type Doc = Required<DocFrontMatter> & {
  slug: string
  component: Component
}

const raw_docs = import.meta.glob<RawDocument>("./policy-documents/*.svx", { eager: true })

function basename(path: string) {
  //These can be hardcoded, because the path we glob-import from is hardcoded
  return path.substring("./policy-documents/".length, path.length - 4)
}

const unsorted_docs: Doc[] = Object.keys(raw_docs).map((key) => {
  const doc = raw_docs[key]

  const metadata = {
    ...doc.metadata,
    created: new Date(doc.metadata.created),
    updated: new Date(doc.metadata.updated),
  }

  // console.log({doc, key, basename: basename(key)})
  return {
    slug: basename(key),
    ...defaults,
    ...metadata,
    component: doc.default,
  }
})

const sorted_docs: Doc[] = unsorted_docs.sort(sortBy('rank', 'title'))

export const docs: Doc[] = sorted_docs
